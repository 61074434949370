import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { recursiveCamelCase } from '@/src/utils';

// The browser handles all 3xx status codes. So axios WILL NEVER EVER receive that response.
// it will only get the data back i.e. the entire page
// So if we really want to force a redirect chances are we have to return a non 3xx code (in most cases).
// i.e. when we log out a user we return a 401 which triggers the correct redirect
// If we have to check for the url or something, then it's in response.request.responseURL
// https://stackoverflow.com/a/15996968
// https://github.com/axios/axios/issues/980
// https://lifesaver.codes/answer/need-some-advice-about-handling-302-redirects-from-ajax
// also set this header...
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
  function (response) {
    if (response.data && response.headers['content-type'].includes('application/json')) {
      response.data = recursiveCamelCase(response.data);
    }
    return response;
  },
  function (error) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (error.response) {
      const location = error.response?.headers?.location;
      // Maintenance Mode
      if (error.response.status === 503) {
        const href = location ?? `/maintenance.html`;
        return Router.push(href);
      }

      // not found
      if (error.response.status === 404) {
        const href = location ?? `/404`;
        return Router.push(href);
      }

      // Unauthorized
      if (error.response.status === 401) {
        const href = location ?? `/accounts/login`;
        return Router.push(href);
      }

      // Other
      if (error.response.status !== 503 && error.response.status !== 401) {
        if (process.env.NEXT_PUBLIC_SENTRY_ENABLED && process.env.NEXT_PUBLIC_SENTRY_DSN)
          Sentry.captureException(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Sentry.captureException(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);
