import { isFinite, set, transform } from 'lodash';
import { STATE_NAMES as _STATE_NAMES } from '../STATES';
import {
  camelCase,
  compose,
  isArray,
  isObjectLike,
  isPlainObject,
  map,
  snakeCase,
  startCase,
  toLower,
} from 'lodash/fp';
import roundTo from 'round-to';
import { TStateAbbreviation } from './types';

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const pluralize = (count: null | number, noun: string, suffix = 's') =>
  `${noun}${(count ?? 0) > 1 ? suffix : ''}`;

export function createHumps(keyConverter: any) {
  function createIteratee(converter: any, self: any) {
    return (result: any, value: any, key: any) =>
      set(result, converter(key), isObjectLike(value) ? self(value) : value);
  }

  return function humps(node: any): any {
    if (isArray(node)) return map(humps, node);
    if (isPlainObject(node)) return transform(node, createIteratee(keyConverter, humps));
    return node;
  };
}

export const recursiveCamelCase = createHumps(camelCase);

export const recursiveSnakeCase = createHumps(snakeCase);

export const undasherize = (v: number | string = '') =>
  !!v && typeof v === 'string' ? v?.replace(/-/g, ' ') : v ? v.toString : '';

export const dasherize = (v: number | string = '') =>
  !!v && typeof v === 'string' ? v?.replace(/\s/g, '-') : v ? v.toString() : '';

export const isTouchDevice = () => {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        // @ts-ignore
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          // @ts-ignore
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      //@ts-ignore
      (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
    )
  );
};

export const clamp = (x: number, min: number, max: number) => (x < min ? min : x > max ? max : x);

export function debounce(callback: any, delay: number) {
  let timer: any | null = null;

  return function () {
    if (timer) return;

    //@ts-ignore
    callback.apply(this, arguments);
    timer = setTimeout(() => (timer = null), delay);
  };
}

export type TTier = '' | '' | 'k' | 'M' | 'G' | 'T' | 'P' | 'E';
export const abbreviateNumber = (d: number, decimalsShown?: number | undefined, tier?: TTier) => {
  const SI_SYMBOL = ['', '', 'k', 'M', 'G', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  const getTier = (v: number) => (Math.log10(Math.abs(v)) / 2) | 0;

  let _tier = tier ? SI_SYMBOL.findIndex(t => t === tier) : getTier(d);

  let res = d;
  // if zero we might not need a suffix
  if (_tier <= 0) {
    res = roundTo(d, 0);
    // if zero again, we don't need a suffix. In the case where after rounding 999.9
    // we should actually diplay 1.0k not 1000.
    _tier = getTier(res);
  }

  let scaled: number;
  if (_tier === 0) {
    scaled = res;
  } else {
    // scale the number only if the tier is > 0
    const scale = Math.pow(10, (_tier - 1) * 3);
    scaled = res / scale;
  }

  // format number
  let formattedValue = null;
  if (decimalsShown !== undefined) {
    formattedValue = scaled.toFixed(decimalsShown);
  } else if (_tier < 2) {
    formattedValue = toLocaleUS(Math.floor(res));
  } else if (_tier === 2) {
    formattedValue = scaled.toFixed(0);
  } else {
    formattedValue = scaled.toFixed(1);
  }

  // get suffix and determine scale
  const suffix = SI_SYMBOL[_tier];
  const value = formattedValue + suffix;

  return {
    originalValue: d,
    formattedValue,
    value,
    suffix,
    tier: _tier,
  };
};

export const toRoundedNumberString = (d: null | number, to = 0): string =>
  d === null || !isFinite(d) ? '-' : `${roundTo(d, to)}`;

export const toPercent = (d: null | number, to: number = 1): number =>
  d === null || !isFinite(d) ? 0 : roundTo(d * 100, to);

export const toPercentString = (d: null | number, to: number = 1) =>
  d === null || !isFinite(d) ? '-' : `${toPercent(d).toFixed(to)}%`;

export const toAbbrTierUSD = (d: null | number, decimalsShown: number, tier: TTier) => {
  if (d === null || !isFinite(d)) return '-';

  const { value } = abbreviateNumber(d, decimalsShown, tier);

  return `$${value}`;
};

export const toAbbrUSD = (d: null | number, to?: number, decimalsShown?: number) => {
  if (d === null || !isFinite(d)) return '-';

  const { value } = abbreviateNumber(d, isFinite(decimalsShown) ? decimalsShown : to);

  return `$${value}`;
};

export const toLocaleUS = (d: null | number) =>
  d === null || !isFinite(d) ? '-' : new Intl.NumberFormat().format(d);

export const toUSD = (d: number | null, to: number = 0) => {
  if (d === null || !isFinite(d)) return '-';

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: to,
    maximumFractionDigits: to,
  }).format(d);
};

export const toMiles = (n: number, decimalsShown?: number) =>
  new Intl.NumberFormat('en-US', {
    unit: 'mile',
    maximumFractionDigits: decimalsShown,
  }).format(n);

export const normalizeCase = (city: string): string => compose(startCase, toLower)(city);

export const STATE_NAMES: Record<TStateAbbreviation | string, string> = _STATE_NAMES;

// https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview?tab=advanced#6
export const isCalendlyEvent = (e: any) => {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
};

export const generatePhotos = (firstPhotoUrl: null | string, numberOfPhotos: null | number) => {
  if (!firstPhotoUrl || !numberOfPhotos || firstPhotoUrl === '') return [];

  if (
    numberOfPhotos <= 1 && // If there are no images homejunction returns a fake image (numberOfphotos = 1) which takes close to 1 min to load blocking the page.
    firstPhotoUrl.includes('listing-images.homejunction')
  ) {
    return [];
  }

  const url = firstPhotoUrl.replace('photo_1.jpg', '');
  let photos = [];
  for (let i = 1; i <= numberOfPhotos; i++) {
    photos.push(`${url}photo_${i}.jpg`);
  }
  return photos;
};
