import * as React from 'react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import Head from 'next/head';
import { Global, ThemeProvider } from '@emotion/react';
// import { BaseStyles, theme } from '@awning/components';
import { BaseStyles } from '@awning/components/dist/BaseStyles';
import { theme } from '@awning/components/dist/utils';
import { StoreProvider } from '@awning/archie';

// @ts-ignore
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';

// Awning component styles
import '@awning/components/index.css';

// Axios config
import '../axios.config';

const scrollPageTop = () => {
  // force scroll to top. Since a lot of our scrolling happens inside sub-divs
  // the auto behavior that nextjs provides doesn't always work
  if (typeof window !== 'undefined') {
    // @ts-ignore
    if (window.__SCROLL_TOP__ !== false) {
      const el = document.getElementById('__next');
      el?.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
    // @ts-ignore
    window.__SCROLL_TOP__ = true;
  }

  NProgress.done();
};
Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', scrollPageTop);
Router.events.on('routeChangeError', _ => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.__SCROLL_TOP__ = true;
  }
  NProgress.done();
});

// @ts-ignore
const STATIC_TITLE = {
  staging: 'staging - Awning',
  development: 'dev - Awning',
  test: 'test - Awning',
  production: 'Awning',
}[process.env.APP_ENV];

export function reportWebVitals(metric: any) {
  if (process.env.NODE_ENV !== 'production' && process.env.NEXT_PUBLIC_SHOW_METRICS === 'true') {
    console.log(metric);
  }
}

const useSmoothScrollPolyfill = () => {
  React.useEffect(() => {
    smoothscroll.polyfill();
  }, []);
};

export default function App({ Component, pageProps }: AppProps) {
  useSmoothScrollPolyfill();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="viewport-fit=cover width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>{STATIC_TITLE}</title>
      </Head>
      <ThemeProvider theme={theme}>
        <BaseStyles>
          <Global
            styles={`
            // We are not really supporting iPhone SE so just make the base font size even smaller
            @media screen and (max-width: 320px) {
              html {
                font-size: 14px;
              }
            }

            #__next {
              height: 100%;
              overflow: auto;
              overflow-x: hidden;
            }

            a {
              cursor: pointer;
              color: inherit;
            }

            #nprogress .bar {
              z-index: 99999;
            }


            @media not all and (min-width:0px) and (max-width:767.98px){.media-at-sm{display:none!important;}}
            @media not all and (min-width:768px) and (max-width:1023.98px){.media-at-md{display:none!important;}}
            @media not all and (min-width:1024px) and (max-width:1279.98px){.media-at-lg{display:none!important;}}
            @media not all and (min-width:1280px){.media-at-xl{display:none!important;}}

            @media not all and (max-width:767.98px){.media-lessthan-md{display:none!important;}}
            @media not all and (max-width:1023.98px){.media-lessthan-lg{display:none!important;}}
            @media not all and (max-width:1279.98px){.media-lessthan-xl{display:none!important;}}

            @media not all and (min-width:768px){.media-greaterthan-sm{display:none!important;}}
            @media not all and (min-width:1024px){.media-greaterthan-md{display:none!important;}}
            @media not all and (min-width:1280px){.media-greaterthan-lg{display:none!important;}}

            @media not all and (min-width:0px){.media-greaterthanorequal-sm{display:none!important;}}
            @media not all and (min-width:768px){.media-greaterthanorequal-md{display:none!important;}}
            @media not all and (min-width:1024px){.media-greaterthanorequal-lg{display:none!important;}}
            @media not all and (min-width:1280px){.media-greaterthanorequal-xl{display:none!important;}}

            @media not all and (min-width:0px) and (max-width:767.98px){.media-between-sm-md{display:none!important;}}
            @media not all and (min-width:0px) and (max-width:1023.98px){.media-between-sm-lg{display:none!important;}}
            @media not all and (min-width:0px) and (max-width:1279.98px){.media-between-sm-xl{display:none!important;}}
            @media not all and (min-width:768px) and (max-width:1023.98px){.media-between-md-lg{display:none!important;}}
            @media not all and (min-width:768px) and (max-width:1279.98px){.media-between-md-xl{display:none!important;}}
            @media not all and (min-width:1024px) and (max-width:1279.98px){.media-between-lg-xl{display:none!important;}}

          `}
          />
          <StoreProvider value={pageProps.__storeData}>
            <Component {...pageProps} />
          </StoreProvider>
        </BaseStyles>
      </ThemeProvider>
    </>
  );
}
